<template>
<div class="imprint-view">
  <div class="header">
    <img src="@/assets/logo-claim-neg.png" alt="f-easy logo" />
    <p>Impressum</p>
  </div>

  <div class="imprint-part">
    <h1>Haftungsausschluss</h1>
    <p>Diese Website enthält Verweise auf Websites, die von Dritten eingerichtet wurden. Wir haben keinerlei Kontrolle über diese Websites und die dort angebotenen Informationen, Waren oder Dienstleistungen und übernehmen daher keinerlei Verantwortung, aus welchem Rechtsgrund auch immer, für den Inhalt der Websites Dritter.
    </p>
  </div>

  <div class="imprint-part">
    <strong>UID Nr.: ATU 73052947</strong>
  </div>

  <div class="imprint-part">
    <h1>Medieninhaber, Herausgeber und Projektleitung</h1>
    <p>dafür gem. GmbH</p>
    <p>J.G. Ulmerstrasse 2</p>
    <p>6850 Dornbirn</p>
    <p>Österreich</p>
  </div>

  <div class="imprint-part">
    <h1>Projektleitung</h1>
    <p>FischKOM PR | Kummunikation, <a href="https://www.fischkom.at">www.fischkom.at</a></p>
    <p>capito "Leicht verständliche Sprache, <a href="https://www.fischkom.at/capito">www.fischkom.at/capito</a></p>
    </p>
  </div>

  <div class="imprint-part">
    <h1>Grafik</h1>
    <p>Franziska Kerbleder, <a href="https://www.kreativsi.at">www.kreativsi.at</a></p>
  </div>

  <div class="imprint-part">
    <h1>Technische Umsetzung</h1>
    <p>SourceNova GmbH, <a href="https://www.sourcenova.at">www.sourcenova.at</a></p>
  </div>

  <div class="imprint-part">
    <h1>Bilder</h1>
    <p>Adobe Stock</p>
  </div>

  <div class="back-link">
    <a class="button" href="/">
      <svg width="11.999999999999998" height="11.999999999999998" xmlns="http://www.w3.org/2000/svg">
      <g>
      <path id="svg_13" d="m5.89988,11.9075c-0.23,0 -0.461,-0.088 -0.637,-0.265c-0.353,-0.352 -0.353,-0.922 0,-1.273l4.415,-4.415l-4.415,-4.416c-0.353,-0.352 -0.353,-0.922 0,-1.273c0.352,-0.354 0.922,-0.354 1.273,0l5.053,5.053c0.169,0.169 0.265,0.397 0.265,0.637s-0.096,0.468 -0.265,0.637l-5.053,5.052c-0.175,0.175 -0.406,0.263 -0.636,0.263zm-4.998,0c-0.23,0 -0.461,-0.088 -0.637,-0.265c-0.353,-0.352 -0.353,-0.922 0,-1.273l4.413,-4.415l-4.414,-4.416c-0.352,-0.352 -0.352,-0.922 0.001,-1.274c0.352,-0.352 0.921,-0.353 1.274,0.001l5.051,5.053c0.352,0.352 0.352,0.922 -0.001,1.273l-5.051,5.052c-0.175,0.176 -0.406,0.264 -0.636,0.264z" fill="#FFFFFF"/>
      </g>
      </svg>
      Zurück
    </a>
  </div>
</div>
</template>

<script>
export default {
  name: 'Imprint'
}
</script>

<style lang="scss">
.imprint-view {
  @media all and (max-width: 450px) {
    .header {
      padding: 20px 0;
    }
  }
  .imprint-part {
    width: 70%;
    margin: 0 auto;
    margin: 20px auto;

    @media all and (max-width: 450px) {
      width: 85%;
    }

    p {
      max-width: 50%;
      @media all and (max-width: 450px) {
        max-width: 90%;
      }
    }

    h1 {
      font-weight: normal;
      font-size: 24px;
      margin: 0;
      @media all and (max-width: 450px) {
        font-size: 20px;
      }
    }

    a {
      color: #389e8c;
      text-decoration: none;
    }
  }

  .back-link {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;

    svg {
      transform: rotate(180deg);
    }

    a.button {
      display: block;
      width: 150px;
    }
  }

  padding-bottom: 200px;
}
</style>
